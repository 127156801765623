<script setup>
import { Link } from '@inertiajs/vue3';

const appNameShort = import.meta.env.VITE_APP_NAME_SHORT;
</script>

<template>
    <div class="bg-cupragray">
        <nav class="container mx-auto px-4">
            <div class="flex items-center gap-8">
                <a href="https://www.cupraofficial.nl/" target="_blank">
                    <div class="flex gap-2 h-16 p-4 fill-copper">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.57 315.559" class="w-12">
                            <path id="logo__image" fill="#95572b" d="M324.875 65.153L258.26 97.746l40.605 15.58c8.363 3.18 18.315 7.282 21.972 17.171 3.657 9.89-1.78 19.079-6.646 26.869l-22.258 34.723q-39.46 61.782-78.985 123.47v-38.157q15.136-47.697 29.445-95.393 4.038-13.61 8.013-27.218c1.59-5.47.572-8.745-4.707-11.352l-40.51-19.937-40.35 19.905c-5.279 2.607-6.36 5.882-4.707 11.352q3.943 13.64 8.013 27.218 14.246 47.887 29.445 95.393v38.157q-39.588-61.687-78.985-123.47l-22.258-34.723c-4.897-7.663-10.398-16.725-6.646-26.869 3.752-10.143 13.61-13.959 21.972-17.17l40.605-15.581L85.663 65.12l20.763 34.819-34.341 13.196Q35.995 56.63 0 0c68.365 24.23 136.73 48.682 205.285 72.117C273.935 48.682 342.205 24.262 410.57 0q-35.932 56.568-72.054 113.008l-34.34-13.195 20.763-34.819"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622 177.613" class="w-32 hidden sm:block">
                            <path id="logo__text" d="M239.66 59.435l-19.303.012v40.017a2.749 2.749 0 01-2.847 2.626H149.43a2.737 2.737 0 01-2.847-2.626V59.447h-19.279v43.748c0 8.258 7.24 14.959 16.162 14.959h80.034c8.92 0 16.161-6.7 16.161-14.96zm142.532 0v58.682l18.677.024V100.25h63.063l11.044 17.892h20.862L483.05 99.513c5.718 0 11.363-5.743 11.363-14.222V73.154c0-8.258-7.24-13.72-16.161-13.72zm-366.03.012C7.24 59.447 0 66.147 0 74.406v28.788c0 8.259 7.24 14.96 16.161 14.96h96.06v-16.015h-92.87a2.749 2.749 0 01-2.846-2.626V78.198a2.749 2.749 0 012.884-2.688h92.833V59.447zm238.408 0v58.706h18.199v-17.891h78.18c12.48 0 16.162-6.7 16.162-14.959V73.166c0-8.258-4.295-13.719-16.162-13.719zm271.026.012c-8.921 0-16.161 6.7-16.161 14.96l.159 43.759h18.162v-17.892h76.082v17.868H622l-.062-58.695c-.012.062-.11.123-.282 0zm4.82 12.822a2.749 2.749 0 01.088.002h73.334v13.744c-26.175-.062-76.18-.197-76.18-.074V74.91a2.749 2.749 0 012.757-2.628zm-129.547.014l74.095.013a2.749 2.749 0 012.847 2.626v8.516a2.749 2.749 0 01-2.847 2.626H400.87v-13.78zm-53.037.01a2.749 2.749 0 012.737 2.629v8.516a2.749 2.749 0 01-2.847 2.626h-74.953V72.307h74.953a2.749 2.749 0 01.11-.001z"/>
                        </svg>
                    </div>
                </a>

                <Link :href="route('cars.index')" class="text-white">
                    {{ appNameShort }}
                </Link>
                <a href="https://www.cupraofficial.nl/" target="_blank" class="text-white">
                    CUPRAofficial.nl
                </a>
                <a href="https://www.cupraofficial.nl/contact" target="_blank" class="text-white hidden sm:inline-block">
                    Contact
                </a>
            </div>
        </nav>
    </div>
</template>