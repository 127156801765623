<script setup>
import { Head } from '@inertiajs/vue3';

defineProps({
    meta: {
        type: Object,
        required: true,
    },
    url: {
        type: String,
    },
});

const appName = import.meta.env.VITE_APP_NAME;
</script>

<template>
    <Head :title="meta.title">
        <meta v-if="meta.title" property="og:title" :content="`${meta.title} | ${appName}`" />
        <meta v-else property="og:title" :content="appName" />

        <meta v-if="meta.description" name="description" :content="meta.description" />
        <meta v-if="meta.description" property="og:description" :content="meta.description" />
        <meta v-if="meta.type" property="og:type" :content="meta.type" />
        <meta v-if="meta.url" property="og:URL" :content="meta.url" />
        <meta v-if="meta.image" property="og:image" :content="meta.image" />

        <link v-if="url" rel="canonical" :href="url" />
    </Head>
</template>